import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import "./footer.scss"
export default function Footer() {
  return (
    <footer className="d-flex ">
      <div className="col-12 d-flex justify-content-md-start justify-content-between flex-wrap">
        <div className="footer-item">
          <div className="heading">Our address</div>
          <div className="text">
            Tapl Solar
            <br />
            18 OAK ST #2193
            <br />
            BRENTWOOD, CA 94513-9998
            <br />
            USA
          </div>
        </div>
        <div className="footer-item">
          <div className="heading">Our Contacts</div>
          <div className="text">
            hello@taplsolar.com
            <br />
            (408) 669-1234
          </div>
        </div>
        <div className="footer-item">
          <div className="heading">Follow us on</div>
          <div className="d-flex">
            <StaticImage
              className="my-2 mr-2"
              placeholder="blurred"
              src="../images/socialIcons/fb.png"
              alt="facebook"
            />
            <StaticImage
              className="m-2"
              placeholder="blurred"
              src="../images/socialIcons/insta.png"
              alt="facebook"
            />
            <StaticImage
              className="m-2"
              placeholder="blurred"
              src="../images/socialIcons/linkedin.png"
              alt="facebook"
            />
          </div>
          <div>
            <Link to="/terms">Terms and Conditions</Link>
          </div>
          <div>
            <Link to="/privacy">Privacy Policy</Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

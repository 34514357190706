import React, { createContext, useContext, useReducer } from "react"
import Footer from "../components/footer"
import { PaymentForm } from "../interfaces/payment"
import PlanInterface from "../interfaces/plan"

interface GlobalStateInterface {
  cart: PlanInterface[]
  paymentForm: null | PaymentForm,
  permitPlan:PlanInterface|null
}

const initialGlobalState: GlobalStateInterface = {
  cart: [],
  paymentForm: null,
  permitPlan:null
}

const GlobalStateContext = createContext<[GlobalStateInterface, any]>([
  initialGlobalState,
  null,
])

function reducer(state: GlobalStateInterface, action: { type: string; payload: any }) {
  switch (action.type) {
    case "ADD_TO_CART": {
      state = { ...state, cart: [...state.cart, action.payload] }
      break
    }
    case "CLEAR_CART": {
      state = { ...state, cart: [] }
      break
    }
    case "REMOVE_FROM_CART": {
      state = { ...state, cart: state.cart.filter(plan=>plan.id!==action.payload) }
      break
    }
    case "SET_PAYMENT_FORM": {
      state = { ...state, paymentForm: action.payload }
      break
    }
    case "CLEAR_PAYMENT_FORM": {
      state = { ...state, paymentForm: null }
      break
    }
    case "SET_PERMIT_PLAN": {
      state = { ...state, permitPlan: action.payload }
      break
    }
    case "CLEAR_PERMIT_PLAN":{
      state={...state,permitPlan:null}
      break;
    }
  }
  return state
}

export function useGlobalState() {
  return useContext(GlobalStateContext)
}

export default function GlobalState({ children }: { children: any }) {
  const [state, dispatch] = useReducer(reducer, initialGlobalState)
  return (
    <GlobalStateContext.Provider value={[state, dispatch]}>
      {children}
    </GlobalStateContext.Provider>
  )
}
export const wrapper = ({ element }: any) => (
  <GlobalState>
    
    {element}
  <Footer/>
  
  </GlobalState>
)

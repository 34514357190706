// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../../../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pay-with-invoice-tsx": () => import("./../../../src/pages/pay-with-invoice.tsx" /* webpackChunkName: "component---src-pages-pay-with-invoice-tsx" */),
  "component---src-pages-payment-failure-tsx": () => import("./../../../src/pages/payment-failure.tsx" /* webpackChunkName: "component---src-pages-payment-failure-tsx" */),
  "component---src-pages-payment-form-tsx": () => import("./../../../src/pages/payment_form.tsx" /* webpackChunkName: "component---src-pages-payment-form-tsx" */),
  "component---src-pages-payment-success-tsx": () => import("./../../../src/pages/payment-success.tsx" /* webpackChunkName: "component---src-pages-payment-success-tsx" */),
  "component---src-pages-permit-plans-tsx": () => import("./../../../src/pages/permit-plans.tsx" /* webpackChunkName: "component---src-pages-permit-plans-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-store-tsx": () => import("./../../../src/pages/store.tsx" /* webpackChunkName: "component---src-pages-store-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

